import type { Settings } from "react-slick";
import Slider from "react-slick";
import type { TeamObject } from "../../../../_types";
import TeamCard from "../../../TeamCard/TeamCard";
import "./style.scss";

type Props = {
  data: TeamObject[];
  onSelect: (id: number) => void;
};

const settings: Settings = {
  dotsClass: "nav-dot",
  speed: 750,
  infinite: true,
  initialSlide: 0,
  slidesToShow: 6,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3500,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 5,
        dots: true,
      },
    },
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: 4,
        dots: true,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 3,
        dots: true,
      },
    },
    {
      breakpoint: 650,
      settings: {
        slidesToShow: 2,
        dots: true,
      },
    },
    {
      breakpoint: 350,
      settings: {
        slidesToShow: 1,
        dots: true,
      },
    },
  ],
};

const TeamCarousel = ({ data, onSelect }: Props) => {
  return (
    <div className="team-carousel">
      <Slider {...settings}>
        {data.map((item) => (
          <TeamCard
            data={item}
            key={item.id}
            variant="compact"
            onClick={() => onSelect(item.id)}
          />
        ))}
      </Slider>
    </div>
  );
};

export default TeamCarousel;
