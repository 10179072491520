import type { TeamObject } from "../../_types";
import "./style.scss";

const LinkedIn = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M18.5195 0H1.47656C0.660156 0 0 0.644531 0 1.44141V18.5547C0 19.3516 0.660156 20 1.47656 20H18.5195C19.3359 20 20 19.3516 20 18.5586V1.44141C20 0.644531 19.3359 0 18.5195 0ZM5.93359 17.043H2.96484V7.49609H5.93359V17.043ZM4.44922 6.19531C3.49609 6.19531 2.72656 5.42578 2.72656 4.47656C2.72656 3.52734 3.49609 2.75781 4.44922 2.75781C5.39844 2.75781 6.16797 3.52734 6.16797 4.47656C6.16797 5.42188 5.39844 6.19531 4.44922 6.19531ZM17.043 17.043H14.0781V12.4023C14.0781 11.2969 14.0586 9.87109 12.5352 9.87109C10.9922 9.87109 10.7578 11.0781 10.7578 12.3242V17.043H7.79688V7.49609H10.6406V8.80078H10.6797C11.0742 8.05078 12.043 7.25781 13.4844 7.25781C16.4883 7.25781 17.043 9.23438 17.043 11.8047V17.043Z"
        fill="#121B22"
      />
    </svg>
  );
};

type Props = {
  data: TeamObject;
  variant: "compact" | "details";
  onClick?: () => void;
};

const TeamCard = ({ data, variant, onClick }: Props) => {
  return (
    <div className="team-card" onClick={onClick}>
      {variant === "compact" && (
        <div className="team-display-compact">
          <img src={data.photo} alt={`${data.name} card image`} />
          <div className="bottom">
            <div className="info">
              <p className="name">{data.name}</p>
              <p className="position">{data.position}</p>
            </div>
            <div className="socials">
              {data.linkedIn !== undefined && (
                <a
                  href={data.linkedIn}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={`${data.name} linkedIn platform external profile link`}
                >
                  <LinkedIn />
                </a>
              )}
            </div>
          </div>
        </div>
      )}
      {variant === "details" && (
        <div className="team-display-details">
          <img src={data.photo} alt={`${data.name} image`} />
          <div className="info">
            <p className="name">{data.name}</p>
            <p className="position">{data.position}</p>
            <div className="socials">
              {data.linkedIn !== undefined && (
                <a
                  href={data.linkedIn}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={`${data.name} linkedIn platform external profile link`}
                >
                  <LinkedIn />
                </a>
              )}
            </div>
          </div>
          <p className="description">{data.description}</p>
        </div>
      )}
    </div>
  );
};

export default TeamCard;
