import { t } from "i18n:astro";
import ExpandableSection from "../../../components/layout/ExpandableSection/ExpandableSection";
import { ExpandableSectionSize } from "../../../components/layout/ExpandableSection/types";
import { ExpandableId } from "../../../data/expandable";
import { ElementTheme } from "../../../types/theme";
import Team from "./components/Team/Team";
import "./style.scss";

const expandableConfig = {
  theme: ElementTheme.LIGHT,
  size: ExpandableSectionSize.XL,
};

const About = () => {
  return (
    <div id="about-container">
      <ExpandableSection
        id={ExpandableId.ABOUT_1}
        title={t("home:mainExpand.about.title")}
        {...expandableConfig}
      >
        {[
          t("home:mainExpand.about.content.0"),
          t("home:mainExpand.about.content.1"),
          t("home:mainExpand.about.content.2"),
          t("home:mainExpand.about.content.3"),
        ].map((v, i) => (
          <p key={i}>{v}</p>
        ))}
      </ExpandableSection>
      <ExpandableSection
        id={ExpandableId.ABOUT_2}
        title={t("home:mainExpand.team.title")}
        {...expandableConfig}
      >
        <Team />
      </ExpandableSection>
      <ExpandableSection
        id={ExpandableId.ABOUT_3}
        title={t("home:mainExpand.investment-opportunities.title")}
        {...expandableConfig}
      >
        {[
          t("home:mainExpand.investment-opportunities.content.0"),
          t("home:mainExpand.investment-opportunities.content.1"),
          t("home:mainExpand.investment-opportunities.content.2"),
        ].map((v, i) => (
          <p key={i}>{v}</p>
        ))}
      </ExpandableSection>
      <ExpandableSection
        id={ExpandableId.ABOUT_4}
        title={t("home:mainExpand.our-values.title")}
        {...expandableConfig}
      >
        {[
          t("home:mainExpand.our-values.content.0"),
          t("home:mainExpand.our-values.content.1"),
        ].map((v, i) => (
          <p key={i}>{v}</p>
        ))}
      </ExpandableSection>
    </div>
  );
};

export default About;
