import { motion } from "framer-motion";
import type { TeamObject } from "../../_types";
import TeamCard from "../TeamCard/TeamCard";
import "./style.scss";

type GridProps = {
  data: TeamObject[];
  onSelect: (id: number) => void;
};

const TeamGrid = ({ data, onSelect }: GridProps) => {
  return (
    <motion.div
      className="team-grid"
      transition={{ duration: 0.3 }}
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
    >
      {data.map((item) => (
        <TeamCard
          data={item}
          key={item.order}
          variant="compact"
          onClick={() => onSelect(item.id)}
        />
      ))}
    </motion.div>
  );
};

export default TeamGrid;
